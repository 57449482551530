<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">Хүргэлтийн бүс нэмэх</h3>
    </div>
    <el-row :gutter="20">
      <el-col :span="10">
        <el-form
          v-if="zoneForm"
          :model="zoneForm"
          :rules="rules"
          ref="zoneForm"
        >
          <div class="panel">
            <el-button size="small" type="success" @click="save(zoneForm)">
              Бүс хадгалах</el-button
            >
            <el-form-item label="Бүсийн нэр" prop="zone_name">
              <el-input v-model="zoneForm.zone_name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="zoneForm.is_active">
                Идэвхитэй
              </el-checkbox>
            </el-form-item>
            <el-form-item label="Тайлбар" prop="description">
              <el-input
                v-model="zoneForm.description"
                :rows="5"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="Зассан шалтгаан" prop="desc">
              <el-input
                v-model="zoneForm.desc"
                :rows="2"
                type="textarea"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </el-col>
      <el-col :span="14">
        <el-radio-group v-model="map_type">
          <el-radio label="polygon">Polygon</el-radio>
          <el-radio label="hexagon">Hexagon</el-radio>
        </el-radio-group>
        <el-popover
          v-if="map_type === 'hexagon'"
          style="padding-left: 60%"
          placement="right"
          width="280"
          trigger="click"
        >
          <el-table :data="hexagonTableDataExplain">
            <el-table-column
              width="50"
              property="H3"
              label="H3"
            ></el-table-column>
            <el-table-column
              width="150"
              property="long"
              label="Урт(1 нүд)"
            ></el-table-column>
          </el-table>
          <el-button type="mini" slot="reference">Тайлбар</el-button>
        </el-popover>
        <!-- <div class="partner-google-map"> -->
        <div v-if="map_type === 'polygon'">
          <div class="partner-google-map">
            <gmap-map
              :center="center"
              :zoom="12"
              :options="mapOptions"
              :draggable="true"
              style="width:100%;  height: 100%; margin-top: 5px;"
            >
              <gmap-polygon
                v-if="zoneForm"
                :paths="zoneForm.polygon[0].points"
                :options="{
                  fillColor: '#000000',
                  strokeColor: '#07bf10',
                  strokeOpacity: 0.9,
                  fillOpacity: 0.1
                }"
                :editable="true"
                @paths_changed="updatePolygonPaths($event)"
              >
              </gmap-polygon>
              <gmap-polygon
                v-for="(item, index) in otherPolygons"
                :key="index"
                :paths="item.polygon"
                :title="item.zone_name"
                :options="{
                  fillColor: '#000000',
                  strokeColor: 'grey',
                  strokeOpacity: 0.4,
                  fillOpacity: 0.1
                }"
                @mouseover="statusText = item.zone_name"
                @mouseout="statusText = null"
              >
              </gmap-polygon>
              <div slot="visible" v-if="statusText">
                <div
                  style="bottom: 0; left: 20; background-color: #0000FF; color: white; padding:20px; position: absolute; z-index: 100"
                >
                  {{ statusText }}
                </div>
              </div>
            </gmap-map>
          </div>
        </div>
        <div v-else-if="map_type === 'hexagon'">
          <div class="panel">
            H3: {{ hexagonTable[zoomSize] }}
            <div class="partner-google-map">
              <gmap-map
                ref="map"
                :center="center"
                :zoom="gmapDefaultZoom"
                :options="mapOptions"
                :draggable="true"
                style="width:100%;  height: 100%; margin-top: 5px;"
              >
                <gmap-marker
                  :position="position"
                  :clickable="true"
                  @position_changed="changeLoc($event)"
                  :draggable="true"
                />
                <!-- <gmap-info-window
                  :options="{
                    maxWidth: 300,
                    pixelOffset: { width: 0, height: -35 }
                  }"
                  :position="infoWindow.position"
                  :opened="infoWindow.open"
                  @closeclick="infoWindow.open = false"
                >
                  <div v-html="infoWindow.template"></div>
                </gmap-info-window> -->
                <gmap-polygon
                  v-for="(item, index) in getDefaultHexagonList()"
                  :key="index"
                  :paths="item.points"
                  :options="{
                    fillColor: hexagon_zone.allowed_list.includes(item.label)
                      ? '#000000'
                      : '#FFFFFF',
                    strokeOpacity: 0.2,
                    fillOpacity: getPolygonFillOpacity(
                      'allowed_list',
                      item.label
                    )
                      ? 0.5
                      : 0.1
                  }"
                  @click="polygonClick(item, index)"
                  :editable="item.editable"
                >
                </gmap-polygon>
                <gmap-polygon
                  v-for="(item, index) in otherHexagonList()"
                  :key="index + item._id"
                  :paths="item.points"
                  :title="item.label"
                  :options="{
                    fillColor: item.color_code,
                    strokeColor: 'grey',
                    strokeOpacity: 0.4,
                    fillOpacity: 0.2
                  }"
                  @mouseover="statusHexagonText = item.label"
                  @mouseout="statusHexagonText = null"
                >
                </gmap-polygon>
                <div slot="visible" v-if="statusHexagonText">
                  <div
                    style="bottom: 0; left: 20; background-color: #0000FF; color: white; padding:20px; position: absolute; z-index: 100"
                  >
                    {{ statusHexagonText }}
                  </div>
                </div>
              </gmap-map>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </el-col>
      <el-col :span="24">
        <el-form
          v-if="zoneForm"
          :model="zoneForm"
          :rules="rules"
          ref="zoneForm"
        >
          <div class="panel">
            <header>
              Тохиргоонууд
              <el-button class="ml20" size="mini" @click="addRule()"
                ><i class="el-icon-plus"></i
              ></el-button>
            </header>
            <el-table
              style="width: 100%"
              :data="zoneForm.zone_configs"
              class="zoneRuleTable"
            >
              <el-table-column label="TPR MIN" prop="tplh_min">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`zone_configs.${scope.$index}.tplh_min`"
                    :rules="rules.tplh_min"
                  >
                    <el-input
                      type="number"
                      size="mini"
                      v-if="scope.row.isEditable"
                      v-model="scope.row.tplh_min"
                    ></el-input>
                    <span v-else>{{ scope.row.tplh_min }}</span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="TPR MAX">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`zone_configs.${scope.$index}.tplh_max`"
                    :rules="rules.tplh_max"
                  >
                    <el-input
                      size="mini"
                      type="number"
                      v-if="scope.row.isEditable"
                      v-model="scope.row.tplh_max"
                    ></el-input>
                    <span v-else>{{ scope.row.tplh_max }}</span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="Үнэ">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`zone_configs.${scope.$index}.base_price`"
                    :rules="rules.tplh_max"
                  >
                    <el-input
                      size="mini"
                      type="number"
                      v-if="scope.row.isEditable"
                      v-model="scope.row.base_price"
                    ></el-input>
                    <span v-else>{{ scope.row.base_price }}</span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="КМ үнэ">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`zone_configs.${scope.$index}.km_price`"
                    :rules="rules.km_price"
                  >
                    <el-input
                      size="mini"
                      v-if="scope.row.isEditable"
                      type="number"
                      v-model="scope.row.km_price"
                    ></el-input>
                    <span v-else>{{ scope.row.km_price }}</span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="Нэмэлт үнэ">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`zone_configs.${scope.$index}.add_price`"
                    :rules="rules.add_price"
                  >
                    <el-input
                      size="mini"
                      type="number"
                      v-if="scope.row.isEditable"
                      v-model="scope.row.add_price"
                    ></el-input>
                    <span v-else>{{ scope.row.add_price }}</span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="Хугацаа">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`zone_configs.${scope.$index}.delivery_time`"
                    :rules="rules.delivery_time"
                  >
                    <el-input
                      size="mini"
                      type="number"
                      v-if="scope.row.isEditable"
                      v-model="scope.row.delivery_time"
                    ></el-input>
                    <span v-else>{{ scope.row.delivery_time }}</span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="Апп дээр харагдах текст">
                <template slot-scope="scope">
                  <el-form-item>
                    <el-select
                      v-if="scope.row.isEditable"
                      v-model="scope.row.msg_text"
                      placeholder="Илгээх текст сонгоно уу"
                      filterable
                      allow-create
                      size="mini"
                      clearable
                    >
                      <el-option
                        v-for="(app_txt, index) in app_txts"
                        :label="app_txt.txt"
                        :value="app_txt.txt"
                        :key="index"
                      ></el-option>
                    </el-select>
                    <span
                      v-else-if="scope.row.msg_text.length === 0"
                      class="inline-block"
                    >
                    </span>
                    <span v-else class="inline-block">
                      {{ scope.row.msg_text.substring(0, 20) }} ...
                      <el-tooltip placement="top">
                        <div slot="content">{{ scope.row.msg_text }}</div>
                        <i class="el-icon-view"></i>
                      </el-tooltip>
                    </span>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="Хязгаарлах эсэх?">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`zone_configs.${scope.$index}.is_restrict_order`"
                  >
                    <el-switch
                      v-model="scope.row.is_restrict_order"
                      :active-value="true"
                      :inactive-value="false"
                    ></el-switch>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="120px">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.isEditable"
                    size="mini"
                    type="success"
                    @click="scope.row.isEditable = false"
                    >Хадгалах</el-button
                  >
                  <div v-else>
                    <el-button size="mini" @click="scope.row.isEditable = true">
                      <i class="el-icon-edit"></i>
                    </el-button>
                    <el-button
                      size="mini"
                      @click="removeRule(scope.row)"
                      style="margin-left: 5px"
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getUserName } from "../utils/auth";
import { arrayIncludes } from "../helpers/helper";
import * as axios from "axios";
import {
  geoToH3,
  getDestinationH3IndexFromUnidirectionalEdge,
  getH3UnidirectionalEdgesFromHexagon,
  h3ToGeoBoundary,
  h3ToChildren
} from "h3-js";
export default {
  name: "AddDriverZone",
  mounted() {
    this.getDeliveryZone(this.$route.path);
    this.getZones();
    this.getDefaultHexagonList();
    setTimeout(async () => {
      this.$refs.map.$on("zoom_changed", zoomSize => {
        this.zoomSize = zoomSize;
        this.hexagonResourceSize = this.hexagonTable[zoomSize];
      });
    }, 5000);
  },
  data() {
    return {
      statusText: null,
      app_txts: [
        {
          txt:
            "Уучлаарай. Замын ачаалал болон шинэ жилийн ачааллаас үүдэн хүргэлтийн хугацаа сунгагдлаа."
        },
        {
          txt:
            "Уучлаарай. Бид ачаалал ихтэй, хүргэгчийн хомсдолтой байгаа тул таны захиалгыг 2 цагийн дотор хүргэх боломжтой байна."
        },
        {
          txt:
            "Уучлаарай. Бид маш их ачаалалтай, хүргэгчийн хомсдолтой байгаа тул таны захиалгыг 3 цагийн дотор хүргэх боломжтой байна."
        },
        {
          txt:
            "Уучлаарай. Бид маш их ачаалалтай, хүргэгчийн хомсдолтой, зам түгжрэлтэй байгаа тул таны захиалгыг 3-с дээш цагийн дараа хүргэх боломжтой байна"
        }
      ],
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      baseData: {
        zone_name: "",
        description: "",
        is_active: false,
        zone_configs: [],
        polygon: [
          {
            type: "polygon",
            coordinates: [
              [
                [106.91600178903806, 47.915773225034776],
                [106.89498393967285, 47.92363207137343],
                [106.93144588588865, 47.92698592131538],
                [106.91600178903806, 47.915773225034776]
              ]
            ]
          }
        ]
      },
      res: null,
      center: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      },
      position: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      },
      zoneForm: null,
      map_type: "polygon",
      gmapDefaultZoom: 13,
      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        template: ""
      },
      nearby_outlets: [],
      hexagon_zone: {
        allowed_list: [],
        blocked_list: []
      },
      hexagonResourceSize: 8,
      hexagonTable: {
        "20": 14,
        "19": 13,
        "18": 12,
        "17": 11,
        "16": 10,
        "15": 9,
        "14": 8,
        "13": 7,
        "12": 6,
        "11": 5,
        "10": 4,
        "9": 3,
        "8": 2,
        "7": 1
      },
      hexagonTableDataExplain: [
        {
          H3: "0",
          long: "1,107.712591000"
        },
        {
          H3: "1",
          long: "418.676005500"
        },
        {
          H3: "2",
          long: "158.244655800"
        },
        {
          H3: "3",
          long: "59.810857940"
        },
        {
          H3: "4",
          long: "22.606379400"
        },
        {
          H3: "5",
          long: "8.544408276"
        },
        {
          H3: "6",
          long: "3.229482772"
        },
        {
          H3: "7",
          long: "1.220629759"
        },
        {
          H3: "8",
          long: "0.461354684"
        },
        {
          H3: "9",
          long: "0.174375668"
        },
        {
          H3: "10",
          long: "0.065907807"
        },
        {
          H3: "11",
          long: "0.024910561"
        },
        {
          H3: "12",
          long: "0.009415526"
        },
        {
          H3: "13",
          long: "0.003559893"
        },
        {
          H3: "14",
          long: "0.001348575"
        },
        {
          H3: "15",
          long: "0.000509713"
        }
      ],
      zoomSize: null,
      rules: {
        zone_name: [
          {
            required: true,
            message: "Бүсийн нэр оруулна уу",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "тайбар нэр оруулна уу",
            trigger: "blur"
          }
        ],
        tplh_min: [
          {
            required: true,
            message: "TPH MIN оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        tplh_max: [
          {
            required: true,
            message: "tplh_max оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        base_price: [
          {
            required: true,
            message: "Үнэ оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        km_price: [
          {
            required: true,
            message: " км-н үнэ оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        delivery_time: [
          {
            required: true,
            message: "хугацаа оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        desc: [
          {
            required: true,
            message: "Засах шалтгаан оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        add_price: [
          {
            required: true,
            message: "Нэмэлт үнэ оруулаагүй байна.",
            trigger: "blur"
          }
        ]
      },
      otherPolygons: null,
      otherHexagons: null,
      statusHexagonText: null
    };
  },
  methods: {
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    updatePolygonPaths(event) {
      let paths = [];
      for (let i = 0; i < event.getLength(); i++) {
        for (let j = 0; j < event.getAt(i).getLength(); j++) {
          let point = event.getAt(i).getAt(j);
          paths.push([point.lng(), point.lat()]);
        }
      }
      if (
        paths[0][0] !== paths[paths.length - 1][0] ||
        paths[0][1] !== paths[paths.length - 1][1]
      ) {
        paths.push(paths[0]);
      }
      this.zoneForm.polygon[0].coordinates[0] = paths;
    },
    addRule() {
      this.zoneForm.zone_configs.push({
        tplh_min: null,
        tplh_max: null,
        km_price: null,
        base_price: null,
        delivery_time: null,
        msg_text: "",
        isEditable: true,
        add_price: null
      });
    },
    removeRule(val) {
      this.zoneForm.zone_configs = this.zoneForm.zone_configs.filter(
        rule => rule !== val
      );
    },
    async save(data) {
      this.$refs.zoneForm.validate(valid => {
        if (valid) {
          getUserName().then(async name => {
            let payload = {};
            let zone_config = {};
            let zone_configs = [];
            payload.zone_name = data.zone_name;
            payload.description = data.description;
            payload.modified_by = name.attributes.email.split("@")[0];
            payload.desc = data.desc;
            payload.is_active = data.is_active;
            payload.polygon = [
              {
                type: "polygon",
                coordinates: data.polygon[0].coordinates
              }
            ];
            payload.hexagon_zone = this.hexagon_zone;
            this.zoneForm.zone_configs.forEach(el => {
              zone_config.base_price = el.base_price;
              zone_config.add_price = el.add_price;
              zone_config.km_price = el.km_price;
              zone_config.tplh_min = el.tplh_min;
              zone_config.tplh_max = el.tplh_max;
              zone_config.delivery_time = el.delivery_time;
              zone_config.msg_text = el.msg_text;
              zone_configs.push(zone_config);
              zone_config = {};
            });
            payload.zone_configs = data.zone_configs;
            try {
              if (this.$route.path === "/add-driver-zone") {
                const response = await axios.post(
                  process.env.VUE_APP_DRIVER_API +
                    "admin/delivery-zone/createDeliveryZone",
                  payload
                );
                if (response.data.status === "success") {
                  this.$notify({
                    title: "Амжилттай",
                    message: "Амжилттай хадгалаглаа",
                    type: "success"
                  });
                  this.$router.go(-1);
                } else {
                  throw new Error(response.data.error.message);
                }
              } else if (this.$route.path === "/edit-driver-zone") {
                payload.id = data._id;
                const response = await axios.post(
                  process.env.VUE_APP_DRIVER_API +
                    "admin/delivery-zone/updateDeliveryZone",
                  payload
                );
                if (response.data.status === "success") {
                  this.$notify({
                    title: "Амжилттай",
                    message: "Амжилттай хадгалаглаа",
                    type: "success"
                  });
                  this.$router.go(-1);
                } else {
                  throw new Error(response.data.error.message);
                }
              }
            } catch (err) {
              this.$notify.error({
                title: "Алдаа",
                message: err.message
              });
            }
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    async getDeliveryZone(path) {
      try {
        if (path === "/edit-driver-zone") {
          const payload = {};
          payload.id = this.$route.query.id;
          const res = await axios.post(
            process.env.VUE_APP_DRIVER_API +
              "admin/delivery-zone/getDeliveryZoneById",
            payload
          );
          if (res.data.status === "success") {
            this.zoneForm = res.data.response;
            this.hexagon_zone = res.data.response.hexagon_zone;
            this.otherPolygons = this.zoneForm.other_polygons;
            this.otherHexagons = res.data.response.other_hexagons;
            if (this.zoneForm.hasOwnProperty("_id")) {
              this.zoneForm.id = this.zoneForm._id;
            }
            if (this.zoneForm.hasOwnProperty("polygon")) {
              this.zoneForm.polygon.map(polygonItem => {
                let tempPolygon = [];
                polygonItem.coordinates[0].map(element => {
                  tempPolygon.push({ lat: element[1], lng: element[0] });
                });
                polygonItem.points = tempPolygon;
              });
            }
            this.zoneForm.zone_configs.forEach(el => {
              el.isEditable = false;
            });
          } else {
            throw new Error(res.data.error.message);
          }
        } else if (path === "/add-driver-zone") {
          this.zoneForm = this.baseData;
          this.otherPolygons = [];
          this.otherHexagons = [];
          if (this.zoneForm.hasOwnProperty("polygon")) {
            this.zoneForm.polygon.map(polygonItem => {
              let tempPolygon = [];
              polygonItem.coordinates[0].map(element => {
                tempPolygon.push({ lat: element[1], lng: element[0] });
              });
              polygonItem.points = tempPolygon;
            });
          }
        }
      } catch (err) {
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },
    async getZones() {
      try {
        const axiosResponse = await axios.get(
          process.env.VUE_APP_DRIVER_API + "admin/delivery-zone/getPolygons"
        );
        if (axiosResponse.data.status !== "success") {
          throw new Error(axiosResponse.data.error.message);
        }
        this.otherPolygons = axiosResponse.data.response.data;
        this.otherHexagons = axiosResponse.data.response.data;
      } catch (err) {
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },
    getDefaultHexagonList() {
      let tempHexagonList = [];
      let tempData = [];

      const outletHexagon = geoToH3(
        this.position.lat,
        this.position.lng,
        this.hexagonResourceSize
      );
      tempHexagonList = this.getNeighbourHexagons(outletHexagon);

      tempHexagonList.forEach(el => {
        let hexagons = h3ToChildren(el, this.hexagonResourceSize + 1);
        let tempHexagonList = [];
        hexagons.forEach(hexagon => {
          tempHexagonList = tempHexagonList.concat(
            this.getNeighbourHexagons(hexagon)
          );
        });
        hexagons = hexagons.concat(tempHexagonList);
        hexagons = [...new Set(hexagons)];
        const calculatedH3 = this.generatePolygonsFromHexagonList(hexagons);
        tempData = tempData.concat(calculatedH3);
      });
      this.hexagon_zone.allowed_list.forEach(el => {
        tempHexagonList = tempHexagonList.concat(this.getNeighbourHexagons(el));
      });
      const calculatedH3List = this.generatePolygonsFromHexagonList(
        this.hexagon_zone.allowed_list
      ).concat(this.generatePolygonsFromHexagonList(tempHexagonList));
      return calculatedH3List;
    },
    getPolygonFillColor(type, hex) {
      if (type === "hexogon") {
        return this.hexagon_zone.allowed_list.includes("hexogon", hex)
          ? "#000000"
          : "#FFFFFF";
      }
      return "#FFFFFF";
    },
    getPolygonFillOpacity(type) {
      if (type === "allowed_list") {
        return true;
      }
      return false;
    },
    polygonClick(item) {
      let currentAllowedList = JSON.parse(
        JSON.stringify(this.hexagon_zone.allowed_list)
      );
      if (!currentAllowedList.includes(item.label)) {
        this.hexagon_zone.allowed_list.push(item.label);
      } else {
        this.hexagon_zone.allowed_list = currentAllowedList.filter(
          el => el !== item.label
        );
      }
      this.$forceUpdate();
    },
    getNearbyOutlets(lat, lng) {
      axios
        .post(`${process.env.VUE_APP_ADMIN_BASE}/getNearbyOutletById`, {
          lat: lat,
          lon: lng,
          outlet_id: this.zoneForm.outlet_id
        })
        .then(res => {
          if (res.data.status == "success") {
            this.nearby_outlets = [];
            res.data.data.forEach(el => {
              if (el.location && el.location.split(",").length > 1) {
                el.location = {
                  lat: parseFloat(el.location.split(",")[0]),
                  lng: parseFloat(el.location.split(",")[1])
                };
                this.nearby_outlets.push(el);
              }
            });
          }
        });
    },
    getNeighbourHexagons(h3) {
      let tempList = [];
      tempList.push(h3);
      const edges = getH3UnidirectionalEdgesFromHexagon(h3);
      edges.forEach(el => {
        const calculatedH3 = getDestinationH3IndexFromUnidirectionalEdge(el);
        tempList.push(calculatedH3);
      });
      return tempList;
    },
    changeLoc(event) {
      this.position.lat = event.lat();
      this.position.lng = event.lng();
    },
    generatePolygonsFromHexagonList(hexagonList) {
      hexagonList = [...new Set(hexagonList)];
      const tempData = [];
      hexagonList.forEach(el => {
        const polygon = h3ToGeoBoundary(el);
        let temp = {
          label: el,
          points: []
        };
        polygon.forEach(item => {
          temp.points.push({
            lat: item[0],
            lng: item[1]
          });
        });
        tempData.push(temp);
      });
      return tempData;
    },
    generatePolToHex(hexagonList) {
      const tempData = [];
      hexagonList.forEach(el => {
        const colorCode = this.getRandomColor();
        el.hexagon_zone.allowed_list.forEach(el2 => {
          let temp = {
            label: el.zone_name,
            _id: el._id,
            color_code: colorCode,
            points: []
          };
          const polygon = h3ToGeoBoundary(el2);
          polygon.forEach(item => {
            temp.points.push({
              lat: item[0],
              lng: item[1]
            });
          });
          tempData.push(temp);
        });
      });
      return tempData;
    },
    otherHexagonList() {
      const hexagons = this.generatePolToHex(this.otherHexagons);
      return hexagons;
    },
    getRandomColor() {
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    }
  }
};
</script>
<style scoped>
.el-form-item {
  margin-bottom: 10px;
}
.el-table .cell {
  padding: 0 5px;
}
</style>
